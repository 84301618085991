import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'

function Security() {
    const save = () => {
        Swal.fire({
            icon: 'success',
            title: 'Account has been updated successfully',
            showConfirmButton: false,
            timer: 1500
        })
    }
    return (
        <div>
            <Helmet>

                <script src="https://deluxeprostock.com/dashboard/js/jquery.min.js"></script>
                <script src="https://deluxeprostock.com/dashboard/js/bootstrap.bundle.min.js"></script>
                <script src="https://deluxeprostock.com/dashboard/js/modernizr.js"></script>
                <script src="https://deluxeprostock.com/dashboard/js/moment.js"></script>
                <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/megamenu.js"></script>
                <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/custom.js"></script>
                <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/slimscroll.min.js"></script>
                <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/custom-scrollbar.js"></script>
                <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/search-filter.js"></script>
                <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/custom-search-filter.js"></script>
                <script src="https://deluxeprostock.com/dashboard/vendor/apex/apexcharts.min.js"></script>
                <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/salesGraph.js"></script>
                {/* <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/ordersGraph.js"></script>
<script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/earningsGraph.js"></script>
<script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/visitorsGraph.js"></script>
<script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/customersGraph.js"></script>
<script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/sparkline.js"></script> */}
                <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.min.js"></script>
                <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.custom.js"></script>
                <script src="https://deluxeprostock.com/dashboard/js/main.js"></script>
            </Helmet>

            {/* Loading wrapper end */}
            {/* Page wrapper start */}
            <div className="page-wrapper">
                {/* Sidebar wrapper start */}
                <Nav />
                {/* Sidebar wrapper end */}
                {/* *************
            ************ Main container start *************
        ************* */}
                <div className="main-container">
                    {/* Page header starts */}
                    <div className="page-header">
                        {/* Row start */}
                        <div className="row gutters">
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-9">
                                {/* Search container start */}
                                <div className="search-container">
                                    {/* Toggle sidebar start */}
                                    <div className="toggle-sidebar" id="toggle-sidebar">
                                        <i className="icon-menu" />
                                    </div>
                                    {/* Toggle sidebar end */}
                                </div>
                                {/* Search container end */}
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-3">
                                {/* Header actions start */}
                                <ul className="header-actions">
                                    <li className="dropdown">
                                        <a href="#" id="userSettings" className="user-settings" data-toggle="dropdown" aria-haspopup="true">
                                            Hi Qwertyy &nbsp;
                                            <span className="avatar">
                                                <img src="dashboard/img/user.svg" alt="User Avatar" />
                                                <span className="status busy" />
                                            </span>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-end md" aria-labelledby="userSettings">
                                            <div className="header-profile-actions">
                                                <a href="?a=edit_account"><i className="icon-user1" />Profile</a>
                                                <a href="?a=security"><i className="icon-warning" />Security</a>
                                                <a href="?a=logout"><i className="icon-log-out1" />Logout</a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                {/* Header actions end */}
                            </div>
                        </div>
                        {/* Row end */}
                    </div>
                    {/* Page header ends */}
                    {/* Content wrapper scroll start */}
                    <div className="content-wrapper-scroll">
                        {/* Content wrapper start */}
                        <div className="content-wrapper">
                            {/* Row start */}
                            <div className="row gutters">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    {/* Card start */}
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-title">Advanced login security settings</div>
                                        </div>
                                        <div className="card-body">
                                            <form ><input type="hidden" name="form_id" defaultValue={16866893516564} /><input type="hidden" name="form_token" defaultValue="bfcda7b6123891328fb9aaca5b13f86e" />
                                                <input type="hidden" name="a" defaultValue="security" />
                                                <input type="hidden" name="action" defaultValue="save" />
                                                Detect IP Address Change Sensitivity<br />
                                                <input type="radio" name="ip" defaultValue="disabled" defaultChecked />Disabled<br />
                                                <input type="radio" name="ip" defaultValue="medium" />Medium<br />
                                                <input type="radio" name="ip" defaultValue="high" />High<br />
                                                <input type="radio" name="ip" defaultValue="always" />Paranoic<br /><br />
                                                Detect Browser Change<br />
                                                <input type="radio" name="browser" defaultValue="disabled" defaultChecked />Disabled<br />
                                                <input type="radio" name="browser" defaultValue="enabled" />Enabled<br /><br />
                                                <input type="button" defaultValue="Set" className="btn btn-primary ml-auto" onClick={save} />
                                            </form>
                                        </div></div></div>
                            </div></div>
                    </div></div>
            </div>
            {/* App footer start */}
            <div className="app-footer">© Techsgrowth</div>
        </div>
    )
}

export default Security