import React, { useEffect, useRef, useState, useContext } from 'react'
import { GlobalContext } from "../Globalstate";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from 'react-router-dom';
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import Swal from 'sweetalert2'
import { f, database, storage, auth } from "../config";
import db from "../config";
import firebase from 'firebase';
import emailjs from "emailjs-com";



function Deposit() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair }, dispatch] = useContext(GlobalContext);
  // onchageinvestments
  const [selectedplan, setselectedplan] = useState("")
  const [Selectedinvestment, setSelectedinvestment] = useState("")
  const [Amount, setAmount] = useState(0)
  const [loading, setloading] = useState(true)
  const [Coins, setCoins] = useState([]);
  const [image, setimage] = useState(null);
  const [url, seturl] = useState("");
  const [progress, setprogress] = useState(0);
  const [imagepresnt, setimagepresent] = useState(false);
  const [prevfile, setprevFile] = useState("")
  const [CoinInfo, setCoinInfo] = useState("")
  const [step, setstep] = useState(0)
  const investments = [
    {
      id: "1",
      name: "Bronze",
      price: "$100 - $499",
      tradesPerDay: "2 Trades/day",
      minimum: 100,
      Maximum: 499,
      duration: 1,
      profits: 10,
    },
    {
      id: "2",
      name: "Silver",
      price: "$500 - $999",
      tradesPerDay: "5 Trades/Day",
      minimum: 500,
      Maximum: 999,
      duration: 1,
      profits: 10,
    },
    {
      id: "3",
      name: "Gold",
      price: "$1,000 - $4,999",
      tradesPerDay: "12 Trades/day",
      minimum: 1000,
      Maximum: 4999,
      duration: 1,
      profits: 10,
    },
    {
      id: "4",
      name: "Diamond",
      price: "$5,000 - $9,999",
      tradesPerDay: "25 Trades/Day",
      minimum: 5000,
      Maximum: 9999,
      duration: 1,
      profits: 10,
    },
    {
      id: "5",
      name: "Platinum",
      price: "$10,000 - $49,999",
      tradesPerDay: "35 Trades/Day",
      minimum: 10000,
      Maximum: 49999,
      duration: 1,
      profits: 10,
    },
    {
      id: "6",
      name: "Platinum Pro",
      price: "$50,000 - $99,999",
      tradesPerDay: "50 Trades/Day",
      minimum: 50000,
      Maximum: 99999,
      duration: 1,
      profits: 10,
    },
    {
      id: "7",
      name: "Ultimate Plan",
      price: "$100,000 - $1,000,000.00",
      tradesPerDay: "100 Trades/Day",
      minimum: 100000,
      Maximum: 1000000,
      duration: 1,
      profits: 10,
    },
  ];


  const SetInvestment = (value) => {
    setselectedplan(value)
    let selectedinvestment = investments.find(o => o.id === value);
    console.log(selectedinvestment)
    setSelectedinvestment(selectedinvestment)
  }
  // onchageCryptoCurrency 
  const CoinsList = [
    {
      id: "1",
      name: "BITCOIN",
      address: "bc1qm3czglng6y0r439xez25kgeht3nhuw690j3f88"
    },
    {
      id: "3",
      name: "ETHEREUEM",
      address: "0x4BD527D565A2E7f18FBFc616C99C468e12f03AE0"
    },
    {
      id: "2",
      name: "USDT TRC20",
      address: "TErSkgnzMTTfh8fqUTsT9t8rspfUkBf8nV"
    },
    {
      id: "4",
      name: "USDT ERC20",
      address: "0x4BD527D565A2E7f18FBFc616C99C468e12f03AE0"
    },
  ]
  const SetCoinInfo = (value) => {
    let selectedcoin = CoinsList.find(o => o.id === value);
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: `You Selected ${selectedcoin.name} as Payment Method`,
      showConfirmButton: false,
      timer: 1500
    })
    setCoinInfo(selectedcoin)
  }
  const Confirmation = () => {
    if (Amount != 0 && CoinInfo != "" && Selectedinvestment != "") {
      if (parseInt(Amount) >= Selectedinvestment.minimum && parseInt(Amount) <= Selectedinvestment.Maximum) {
        setstep(1)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'You selected the wrong investment for selected amount',
          footer: '<a href="">Why do I have this issue?</a>'
        })
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'please fill in the required fields!',
        footer: '<a href="">Why do I have this issue?</a>'
      })
    }
  }
  // const Confirmation = () => {
  //   if (Amount != 0 && CoinInfo != "" && Selectedinvestment != "") {
  //     setstep(1)
  //   } else {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Oops...',
  //       text: 'please fill in the required fields!',
  //       footer: '<a href="">Why do I have this issue?</a>'
  //     })
  //   }
  // }
  const handleChange = (e) => {
    if (e.target.files[0]) {
      setimage(e.target.files[0]);
      setimagepresent(true);
      setprevFile(URL.createObjectURL(e.target.files[0]))
    }
  };
  const handleUpload = () => {

    if (Amount != 0 && CoinInfo != "") {
      if (!imagepresnt) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'No Image Was Selected!',
        })
        return
      }
      //check wether amt is empty or file is not selected
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setprogress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              seturl(url);
              updatehistory(url);
              setprogress(0);
              setimagepresent(false)
              sendAdminMaiil()
              Swal.fire(
                'Succesful Transaction!',
                'Your account will be credited once the payment is recieved.',
                'success'
              )
              // alert("we will we credit your balance shortly");
              setimage(null);
              // history("/dashboard")
            });
        }
      );
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'please fill in the required fields!',
        footer: '<a href="">Why do I have this issue?</a>'
      })
    }

  };



  const sendAdminMaiil = async () => {
    var templateParams = {
      message:
        `${userdetails.fullname} made a deposit to his account to activate an investment`,
    };
    await emailjs
      .send(
        "service_5jy1gr4", "template_zntnjos",
        templateParams,
        "HNlgG_D2lYE6E-7Q3"
      )
      .then(
        function (response) {
          // setloading(false)
        },
        function (err) {
          // setloading(false)
          alert("FAILED...", err);
        }
      );
  }

  const CreatUserObj = async (obj) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    let uobj = {
      fullname: userdetails.fullname,
      email: userdetails.email,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      userid: userids,
      info: obj
    };

    const userdetail1 = await db
      .collection("deposits")
      .add(uobj)
      .then(function () {
        console.log("Document successfully written!");
        Swal.fire(
          'Succesful Transaction!',
          'Your Investment will been activated.',
          'success'
        )
        window.location.replace("/dashboard");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
  const updatehistory = async (urls) => {
    var userid = f.auth().currentUser;
    var userids = userid.uid;
    const increment = firebase.firestore.FieldValue.increment(parseInt(Amount));
    CreatUserObj({
      image: urls,
      date: Date.now(),
      amt: Amount,
      mode: "Deposit",
      coin: CoinInfo.name,
      investmentplan: selectedplan
    })
    var washingtonRef = db.collection("users").doc(userids);
    await washingtonRef.update({
      // Totaldeposit: increment,
      Deposithistory: firebase.firestore.FieldValue.arrayUnion({
        image: urls,
        date: Date.now(),
        amt: Amount,
        mode: "Deposit",
        coin: CoinInfo.name,
        investmentplan: selectedplan
      }),
    });
  };




  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }

    let myDate = new Date();
    console.log(myDate.getTime())
    console.log(addHoursToDate(myDate, 1).getTime())
    console.log(myDate)
    console.log(addHoursToDate(myDate, 1))

  }, []);

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .onSnapshot((function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          console.log(doc.data())
          setloading(false)
        } else {
          console.log("No such document!");
        }
      })
      )

  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };


  return (
    <div>
      <Helmet>

        <script src="https://deluxeprostock.com/dashboard/js/jquery.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/bootstrap.bundle.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/modernizr.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/moment.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/megamenu.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/custom.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/slimscroll.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/custom-scrollbar.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/search-filter.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/custom-search-filter.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/apex/apexcharts.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/salesGraph.js"></script>
        {/* <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/ordersGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/earningsGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/visitorsGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/customersGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/sparkline.js"></script> */}
        <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.custom.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/main.js"></script>
      </Helmet>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      {/*--------------- www.hyipcustomize.com ---------*/}
      <meta name="designer" href="https://hyipcustomize.com/" />
      {/* Title */}
      <title>Techsgrowth | Dashboard</title>
      {/* *************
        ************ Common Css Files *************
    ************ */}
      {/* Bootstrap css */}
      <link rel="stylesheet" href="dashboard/css/bootstrap.min.css" />
      {/* Icomoon Font Icons css */}
      <link rel="stylesheet" href="dashboard/fonts/style.css" />
      {/* Main css */}
      <link rel="stylesheet" href="dashboard/css/main.css" />
      {/* *************
        ************ Vendor Css Files *************
    ************ */}
      {/* Mega Menu */}
      <link rel="stylesheet" href="dashboard/vendor/megamenu/css/megamenu.css" />
      {/* Search Filter JS */}
      <link rel="stylesheet" href="dashboard/vendor/search-filter/search-filter.css" />
      <link rel="stylesheet" href="dashboard/vendor/search-filter/custom-search-filter.css" />
      {/* Loading wrapper start */}
      {/* <div id="loading-wrapper">
      <div className="spinner-border" />
      Loading...
    </div> */}
      {/* Loading wrapper end */}
      {/* Page wrapper start */}
      <div className="page-wrapper">
        {/* Sidebar wrapper start */}
        <Nav />
        {/* Sidebar wrapper end */}
        {/* *************
            ************ Main container start *************
        ************* */}
        <div className="main-container">
          {/* Page header starts */}
          <div className="page-header">
            {/* Row start */}
            <div className="row gutters">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-9">
                {/* Search container start */}
                <div className="search-container">
                  {/* Toggle sidebar start */}
                  <div className="toggle-sidebar" id="toggle-sidebar">
                    <i className="icon-menu" />
                  </div>
                  {/* Toggle sidebar end */}
                </div>
                {/* Search container end */}
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-3">
                {/* Header actions start */}
                <ul className="header-actions">
                  <li className="dropdown">
                    <a href="#" id="userSettings" className="user-settings" data-toggle="dropdown" aria-haspopup="true">
                      Hi {userdetails.fullname} &nbsp;
                      <span className="avatar">
                        <img src="https://img.icons8.com/?size=1x&id=kDoeg22e5jUY&format=png" alt="User Avatar" />
                        <span className="status busy" />
                      </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end md" aria-labelledby="userSettings">
                      <div className="header-profile-actions">
                        <a href="?a=edit_account"><i className="icon-user1" />Profile</a>
                        <a href="?a=security"><i className="icon-warning" />Security</a>
                        <a href="?a=logout"><i className="icon-log-out1" />Logout</a>
                      </div>
                    </div>
                  </li>
                </ul>
                {/* Header actions end */}
              </div>
            </div>
            {/* Row end */}
          </div>
          {/* Page header ends */}
          {/* Content wrapper scroll start */}
          <div className="content-wrapper-scroll">
            {/* Content wrapper start */}
            <div className="content-wrapper">
              <br />
              {/* Row start */}
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  {/* Card start */}
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">Make A Deposit</div>
                    </div>
                    <div className="card-body">
                      {/* Row start */}
                      <div className="row gutters">
                        <div className="col-md-12">
                          {/* Card start */}


                          {
                            step == 0 && (
                              <div className="card">
                                <div className="card-header">
                                  <div className="card-title">Fill The Form</div>
                                </div>
                                <div className="card-body">
                                  {/* Row start */}
                                  <div className="row gutters">
                                    <form method="post" name="spendform"><input type="hidden" name="form_id" defaultValue={16862959794119} /><input type="hidden" name="form_token" defaultValue="08bae86c7de245e360aca85c0268335b" />
                                      <input type="hidden" name="a" defaultValue="deposit" />
                                      <div className="col-md-12">
                                        {/* Field wrapper start */}
                                        <div className="field-wrapper">
                                          <input className="form-control" type="text" defaultValue={userdetails.balance} disabled />
                                          <div className="field-placeholder">Your Account Balance</div>
                                        </div>
                                        {/* Field wrapper end */}
                                      </div>
                                      <div className="col-md-12">
                                        {/* Field wrapper start */}
                                        <div className="field-wrapper">
                                          <select className="form-select" id="formSelect" name="h_id" onChange={(e) => { SetInvestment(e.target.value) }}>
                                            {/* <option >Select Plan Plan</option>
                                            <option value="1">Beginner Plan</option>
                                            <option value="2">AMATEUR PLAN</option>
                                            <option value="3">ADVANCE PLAN</option>
                                            <option value="4">PRO PLAN</option>
                                            <option value="5">EXPERT PLAN</option> */}
                                            {investments.map((investment) => (
                                              <option key={investment.id} value={investment.id}>
                                                {investment.name}
                                              </option>
                                            ))}

                                          </select>
                                          <div className="field-placeholder">Select Package</div>
                                        </div>
                                        {/* Field wrapper end */}
                                      </div>
                                      <div className="col-md-12">
                                        {/* Field wrapper start */}
                                        <div className="field-wrapper">
                                          <select className="form-select" id="formSelect" name="type" onChange={(e) => { SetCoinInfo(e.target.value) }}>
                                            <option >Select Payment Method</option>
                                            <option value="1" checked>Bitcoin</option>
                                            <option value="2">Tether USDT TRC20</option>
                                            <option value="4">Tether USDT ERC20</option>
                                            <option value="3">ETHEREUEM</option>
                                          </select>
                                          <div className="field-placeholder">Select Payment Method</div>
                                        </div>
                                        {/* Field wrapper end */}
                                      </div>
                                      <div className="col-md-12">
                                        {/* Field wrapper start */}
                                        <div className="field-wrapper">
                                          <input className="form-control" type="text" name="amount" onChange={(e) => { setAmount(e.target.value) }} />
                                          <div className="field-placeholder">Enter Amount</div>
                                        </div>
                                        {/* Field wrapper end */}
                                      </div>
                                      <div className="col-md-12">
                                        <button className="btn btn-primary" type="button" onClick={Confirmation}>Deposit</button>
                                      </div>
                                    </form>
                                  </div>
                                  {/* Row end */}
                                </div>
                              </div>
                            )}
                          {
                            step == 1 && (
                              <div className="card">
                                <div className="card-header">
                                  <div className="card-title">Deposit Confirmation</div>
                                </div>
                                <div className="card-body">
                                  <br /><br />
                                  Please send your Bitcoin payments to this Bitcoin Address: <b><a href="Bitcoin: 
                CoinInfo.address
                "><font color="red">
                                      {CoinInfo.address}
                                    </font></a></b><br /><br />
                                  <div className="table-responsive">
                                    <table className="table products-table">
                                      <tbody><tr>
                                        <th>Plan:</th>
                                        <td>{Selectedinvestment.name}</td>
                                      </tr>
                                        <tr>
                                          <th>Profit:</th>
                                          <td>{(Selectedinvestment.profits / 100) * Amount} Daily for {Selectedinvestment.duration} day </td>
                                        </tr>
                                        <tr>
                                          <th>Principal Return:</th>
                                          <td>Yes</td>
                                        </tr>
                                        <tr>
                                          <th>Principal Withdraw:</th>
                                          <td>
                                            Available with
                                            0.00% fee</td>
                                        </tr>
                                        <tr>
                                          <th>Credit Amount:</th>
                                          <td>${Amount}</td>
                                        </tr>
                                        <tr>
                                          <th>Deposit Fee:</th>
                                          <td>0.00% + $0.00 (min. $0.00 max. $0.00)</td>
                                        </tr>
                                        <tr>
                                          <th>Debit Amount:</th>
                                          <td>${Amount}</td>
                                        </tr>
                                        <tr>
                                          <th> Debit Amount:</th>
                                          <td>{Amount}</td>
                                        </tr>
                                      </tbody></table>
                                    <br /><br />
                                    <form name="spend"><input type="hidden" name="form_id" defaultValue={16862961841002} /><input type="hidden" name="form_token" defaultValue="066e34da366d885f78e7b290c16190f8" />
                                      <input type="hidden" name="a" defaultValue="deposit" />
                                      <input type="hidden" name="action" defaultValue="confirm" />
                                      <input type="hidden" name="type" defaultValue="process_1006" />
                                      <input type="hidden" name="h_id" defaultValue={1} />
                                      <input type="hidden" name="compound" defaultValue={0} />
                                      <input type="hidden" name="amount" />
                                      <table cellSpacing={0} cellPadding={2} border={0}>
                                        <tbody><tr>
                                          <td colSpan={2}><b>Required Information:</b></td>
                                        </tr>
                                          <tr>
                                            <td>Transaction ID</td>
                                            <td><input type="text" className="inpts" /></td>
                                          </tr>
                                        </tbody></table>
                                      <br />

                                      {/* <input type="button" defaultValue="Save" onClick={handleUpload} className="se" /> */}

                                      <input type="hidden" name="_token" defaultValue="zn8v4I2d4bZyK4wpUA2IxCApAntbf4ODlmrnGzIz" />													<div className="form-group">
                                        <h5 className="text-dark">Upload Payment proof after payment.</h5>
                                        <input type="file" name="proof" onChange={handleChange} className="form-control col-lg-4 bg-light text-dark" required />
                                      </div>
                                      <div className="form-group">


                                        {
                                          imagepresnt && (
                                            <>
                                              <progress id="file" value={progress} max="100"> {progress} </progress>
                                              <input type="button" className="btn btn-dark" defaultValue="Submit Payment" onClick={handleUpload} />
                                            </>

                                          )
                                        }
                                      </div>



                                      &nbsp;
                                      <input type="button" className="sbmt" defaultValue="Cancel" onClick={() => { setstep(0) }} />
                                    </form>
                                  </div></div></div>
                            )
                          }
                          {/* Card end */}
                        </div>
                      </div>
                      {/* Row end */}
                    </div>
                    {/* Content wrapper end */}
                    {/* App footer start */}
                    <div className="app-footer">© Techsgrowth</div>
                    {/* App footer end */}
                  </div>
                  {/* Content wrapper scroll end */}
                </div>
                {/* *************
            ************ Main container end *************
        ************* */}
              </div>
              {/* Page wrapper end */}
              {/* *************
        ************ Required JavaScript Files *************
    ************* */}
              {/* Required jQuery first, then Bootstrap Bundle JS */}
              {/* *************
        ************ Vendor Js Files *************
    ************* */}
              {/* Megamenu JS */}
              {/* Slimscroll JS */}
              {/* Search Filter JS */}
              {/* Apex Charts */}
              {/* Circleful Charts */}
              {/* Main Js Required */}
              {/*Start of Tawk.to Script*/}
              {/*End of Tawk.to Script*/}
            </div></div></div></div></div>
  )
}

export default Deposit