import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'
function Nav() {
  const navigate = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)
  const [step, setstep] = useState(1)

  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          navigate("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setloading(false)
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };
  const logout = async () => {
    const let1 = await setloggedin(false);
    const let2 = await f.auth().signOut();
    const let3 = await navigate("/");
  };



    return (
        <>
                          <nav className="sidebar-wrapper">
        {/* Sidebar content start */}
        <div className="sidebar-tabs">
          {/* Tabs nav start */}
          <div className="nav" role="tablist" aria-orientation="vertical">
            <a href="/dashboard" className="logo">
              <img src="/logo192.png" alt="?a=home" />
            </a>
            <a className="nav-link active" id="home-tab" data-bs-toggle="tab" href="#tab-home" role="tab" aria-controls="tab-home" aria-selected="true">
              <i className="icon-home2" />
              <span className="nav-link-text">Dashboards</span>
            </a>
            <a className="nav-link" id="product-tab" data-bs-toggle="tab" href="#tab-product" role="tab" aria-controls="tab-product" aria-selected="false">
              <i className="icon-attach_money" />
              <span className="nav-link-text">Finance</span>
            </a>
            <a className="nav-link" id="pages-tab" data-bs-toggle="tab" href="#tab-pages" role="tab" aria-controls="tab-pages" aria-selected="false">
              <i className="icon-clock1" />
              <span className="nav-link-text">Account History</span>
            </a>
            <a className="nav-link" id="forms-tab" data-bs-toggle="tab" href="#tab-forms" role="tab" aria-controls="tab-forms" aria-selected="false">
              <i className="icon-settings1" />
              <span className="nav-link-text">Settings</span>
            </a>
            <a className="nav-link" href="?a=logout" aria-selected="false"  >
              <i className="icon-log-out1"  onClick={logout}/>
              <span className="nav-link-text">Logout</span>
            </a>
          </div>
          {/* Tabs nav end */}
          {/* Tabs content start */}
          <div className="tab-content">
            {/* Chat tab */}
            <div className="tab-pane fade show active" id="tab-home" role="tabpanel" aria-labelledby="home-tab">
              {/* Tab content header start */}
              <div className="tab-pane-header">
                <a href="?a=account">Dashboards</a>
              </div>
              {/* Tab content header end */}
              {/* Sidebar menu starts */}
              <div className="sidebarMenuScroll">
                <div className="sidebar-menu">
                  <ul>
                    <li>
                      <a href="/deposit" className="current-page"><h6>Make a Deposit</h6></a>
                      <a href="/Withdrawal" className="current-page"><h6>Withdrawal</h6></a>
                      {/* <a href="/DepositList" className="current-page"><h6>Deposit List</h6></a> */}
                      <a href="/DepositHistory" className="current-page"><h6>Deposit History</h6></a>
                      <a href="/WithdrawalHistory" className="current-page"><h6>Withdrawal History</h6></a>
                      <a href="/ProfitsHistory" className="current-page"><h6>Earning History</h6></a>
                      <a href="/Profile" className="current-page"><h6>Edit Profile</h6></a>
                      <a href="/Refferals" className="current-page"><h6>Referals</h6></a>
                      <a onClick={logout} className="current-page"><h6>Logout</h6></a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Sidebar menu ends */}
            </div>
            {/* Pages tab */}
            <div className="tab-pane fade" id="tab-product" role="tabpanel" aria-labelledby="Finance">
              {/* Tab content header start */}
              <div className="tab-pane-header">
                Finance
              </div>
              {/* Tab content header end */}
              {/* Sidebar menu starts */}
              <div className="sidebarMenuScroll">
                <div className="sidebar-menu">
                  <ul>
                    <li><a href="/deposit">Deposit</a></li>
                    {/* <li><a href="/DepositList">Deposit List</a></li> */}
                    <li><a href="/Withdrawal">Withdraw</a> </li>
                  </ul>
                </div>
              </div>
              {/* Sidebar menu ends */}
            </div>
            {/* Pages tab */}
            <div className="tab-pane fade" id="tab-pages" role="tabpanel" aria-labelledby="pages-tab">
              {/* Tab content header start */}
              <div className="tab-pane-header">
                Account History
              </div>
              {/* Tab content header end */}
              {/* Sidebar menu starts */}
              <div className="sidebarMenuScroll">
                <div className="sidebar-menu">
                  <ul>
                    <li>
                      <a href="/DepositHistory">Deposit History</a>
                    </li>
                    <li>
                      <a href="/ProfitsHistory">Earnings History</a>
                    </li>
                    <li>
                      <a href="/WithdrawalHistory">Withdrawals History</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Sidebar menu ends */}
            </div>
            {/* Forms tab */}
            <div className="tab-pane fade" id="tab-forms" role="tabpanel" aria-labelledby="forms-tab">
              {/* Tab content header start */}
              <div className="tab-pane-header">
                Settings
              </div>
              {/* Tab content header end */}
              {/* Sidebar menu starts */}
              <div className="sidebarMenuScroll">
                <div className="sidebar-menu">
                  <ul>
                    <li>
                      <a href="/Profile">Profile</a>
                    </li>
                    {/* <li>
                      <a href="?a=referallinks">Promotional Tools</a>
                    </li> */}
                    <li>
                      <a href="/Security">Security</a>
                    </li>
                    <li>
                      <a href="/Refferals"> Referrals</a>
                    </li>
                    {/* <li><a href="?a=referallinks"><h6>Promotional Tools</h6></a> </li> */}
                  </ul>                                   
                </div>
              </div>
              {/* Sidebar menu ends */}
            </div>
          </div>
          {/* Tabs content end */}
        </div>
        {/* Sidebar content end */}
      </nav>
        </>
    )
}

export default Nav