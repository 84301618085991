import React, { useEffect, useRef, useState, useContext } from 'react'
import { GlobalContext } from "../Globalstate";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

import { Icon, divIcon, point } from "leaflet";



////  change the Map Default center 
///// change the scattered points


const customIcon = new Icon({
  // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
  iconUrl: require("./icons/placeholder.png"),
  iconSize: [38, 38] // size of the icon
});

// custom cluster icon
const createClusterCustomIcon = function (cluster) {
  return new divIcon({
    html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: point(33, 33, true)
  });
};

// markers
const markerssddddd = [
  {
    geocode: [48.86, 2.3522],
    popUp: "Hello, I am pop up 1"
  },
  {
    geocode: [48.85, 2.3522],
    popUp: "Hello, I am pop up 2"
  },
  {
    geocode: [48.855, 2.34],
    popUp: "Hello, I am pop up 3"
  }
];
const markers = [
  { geocode: [40.7128, -74.0060], popUp: "Tesla Supercharger in New York City" },
  { geocode: [34.0522, -118.2437], popUp: "Tesla Supercharger in Los Angeles" },
  { geocode: [41.8781, -87.6298], popUp: "Tesla Supercharger in Chicago" },
  { geocode: [29.7604, -95.3698], popUp: "Tesla Supercharger in Houston" },
  { geocode: [33.4484, -112.0740], popUp: "Tesla Supercharger in Phoenix" },
  { geocode: [41.2524, -95.9980], popUp: "Tesla Supercharger in Omaha" },
  { geocode: [37.7749, -122.4194], popUp: "Tesla Supercharger in San Francisco" },
  { geocode: [32.7157, -117.1611], popUp: "Tesla Supercharger in San Diego" },
  { geocode: [35.2271, -80.8431], popUp: "Tesla Supercharger in Charlotte" },
  { geocode: [29.9511, -90.0715], popUp: "Tesla Supercharger in New Orleans" },
  { geocode: [33.6846, -117.8265], popUp: "Tesla Supercharger in Irvine" },
  { geocode: [47.6062, -122.3321], popUp: "Tesla Supercharger in Seattle" },
  { geocode: [42.3601, -71.0589], popUp: "Tesla Supercharger in Boston" },
  { geocode: [38.8951, -77.0369], popUp: "Tesla Supercharger in Washington, D.C." },
  { geocode: [39.7392, -104.9903], popUp: "Tesla Supercharger in Denver" },
  { geocode: [36.7783, -119.4179], popUp: "Tesla Supercharger in Fresno" },
  { geocode: [53.3498, -6.2603], popUp: "Tesla Supercharger in Dublin, CA" },
  { geocode: [34.0522, -118.2437], popUp: "Tesla Supercharger in Los Angeles" },
  { geocode: [41.8781, -87.6298], popUp: "Tesla Supercharger in Chicago" },
  { geocode: [29.7604, -95.3698], popUp: "Tesla Supercharger in Houston" },
  { geocode: [33.4484, -112.0740], popUp: "Tesla Supercharger in Phoenix" },
  { geocode: [41.2524, -95.9980], popUp: "Tesla Supercharger in Omaha" },
  { geocode: [37.7749, -122.4194], popUp: "Tesla Supercharger in San Francisco" },
  { geocode: [32.7157, -117.1611], popUp: "Tesla Supercharger in San Diego" },
  { geocode: [35.2271, -80.8431], popUp: "Tesla Supercharger in Charlotte" },
  { geocode: [29.9511, -90.0715], popUp: "Tesla Supercharger in New Orleans" },
  { geocode: [33.6846, -117.8265], popUp: "Tesla Supercharger in Irvine" },
  { geocode: [47.6062, -122.3321], popUp: "Tesla Supercharger in Seattle" },
  { geocode: [42.3601, -71.0589], popUp: "Tesla Supercharger in Boston" },
  { geocode: [38.8951, -77.0369], popUp: "Tesla Supercharger in Washington, D.C." },
  { geocode: [39.7392, -104.9903], popUp: "Tesla Supercharger in Denver" },
  { geocode: [36.7783, -119.4179], popUp: "Tesla Supercharger in Fresno" },
  { geocode: [53.3498, -6.2603], popUp: "Tesla Supercharger in Dublin, CA" },
  { geocode: [34.0522, -118.2437], popUp: "Tesla Supercharger in Los Angeles" },
  { geocode: [41.8781, -87.6298], popUp: "Tesla Supercharger in Chicago" },
  { geocode: [29.7604, -95.3698], popUp: "Tesla Supercharger in Houston" },
  { geocode: [33.4484, -112.0740], popUp: "Tesla Supercharger in Phoenix" },
  { geocode: [41.2524, -95.9980], popUp: "Tesla Supercharger in Omaha" },
  { geocode: [37.7749, -122.4194], popUp: "Tesla Supercharger in San Francisco" },
  { geocode: [32.7157, -117.1611], popUp: "Tesla Supercharger in San Diego" },
  { geocode: [35.2271, -80.8431], popUp: "Tesla Supercharger in Charlotte" },
  { geocode: [29.9511, -90.0715], popUp: "Tesla Supercharger in New Orleans" },
  { geocode: [33.6846, -117.8265], popUp: "Tesla Supercharger in Irvine" },
  { geocode: [47.6062, -122.3321], popUp: "Tesla Supercharger in Seattle" },
  { geocode: [42.3601, -71.0589], popUp: "Tesla Supercharger in Boston" },
  { geocode: [38.8951, -77.0369], popUp: "Tesla Supercharger in Washington, D.C." },
  { geocode: [39.7392, -104.9903], popUp: "Tesla Supercharger in Denver" },
  { geocode: [36.7783, -119.4179], popUp: "Tesla Supercharger in Fresno" },
  { geocode: [53.3498, -6.2603], popUp: "Tesla Supercharger in Dublin, CA" }
];


function TeslaMap() {
  const [{ userdetails, loggedin, tradingpair }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(true)
  const [dataready, setdataready] = useState(false)



  return (
    <div>

      {/* Loading wrapper start */}
      {/* <div id="loading-wrapper">
        <div className="spinner-border" />
        Loading...
      </div> */}
      {/* Loading wrapper end */}
      {/* Page wrapper start */}
      <div className="page-wrapper">

        {/* Sidebar wrapper end */}
        {/* *************
            ************ Main container start *************
        ************* */}
        <div className="main-container">



          <MapContainer center={[39.217929, -102.458952]} zoom={5}>
            {/* OPEN STREEN MAPS TILES */}
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />


            <MarkerClusterGroup
              chunkedLoading
              iconCreateFunction={createClusterCustomIcon}
            >
              {/* Mapping through the markers */}
              {markers.map((marker) => (
                <Marker position={marker.geocode} icon={customIcon}>
                  <Popup>{marker.popUp}</Popup>
                </Marker>
              ))}

            </MarkerClusterGroup>
          </MapContainer>

        </div>
      </div>

      <div className="app-footer">© Techsgrowth</div>

    </div>
  )
}

export default TeslaMap