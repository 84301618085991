import React from 'react'
import { Helmet } from 'react-helmet';
function DepositPayment() {
  return (
    <div className="content-wrapper-scroll">
          <Helmet>
      
      <script src="https://deluxeprostock.com/dashboard/js/jquery.min.js"></script>
    <script src="https://deluxeprostock.com/dashboard/js/bootstrap.bundle.min.js"></script>
    <script src="https://deluxeprostock.com/dashboard/js/modernizr.js"></script>
    <script src="https://deluxeprostock.com/dashboard/js/moment.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/megamenu.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/custom.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/slimscroll.min.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/custom-scrollbar.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/search-filter.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/custom-search-filter.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/apexcharts.min.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/salesGraph.js"></script>
    {/* <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/ordersGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/earningsGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/visitorsGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/customersGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/sparkline.js"></script> */}
    <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.min.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.custom.js"></script>
    <script src="https://deluxeprostock.com/dashboard/js/main.js"></script>
    </Helmet>
    {/* Content wrapper start */}
    <div className="content-wrapper"> 
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          {/* Card start */}
          <div className="card">
            <div className="card-header">
              <div className="card-title">Deposit Confirmation</div>
            </div>
            <div className="card-body">
              <br /><br />
              Please send your Bitcoin payments to this Bitcoin Address: <b><a href="Bitcoin: 
bc1qcmgugyhmqxqh2tcyph23lqs7s85lnxa4jurc9m
"><font color="red"> 
                    bc1qcmgugyhmqxqh2tcyph23lqs7s85lnxa4jurc9m
                  </font></a></b><br /><br />
              <div className="table-responsive">
                <table className="table products-table">
                  <tbody><tr>
                      <th>Plan:</th>
                      <td>Deluxe Dawn</td>
                    </tr>
                    <tr>
                      <th>Profit:</th>
                      <td>5.00% Daily for 1 day </td>
                    </tr>
                    <tr>
                      <th>Principal Return:</th>
                      <td>Yes</td>
                    </tr>
                    <tr>
                      <th>Principal Withdraw:</th>
                      <td>
                        Available with 
                        0.00% fee</td>
                    </tr>
                    <tr>
                      <th>Credit Amount:</th>
                      <td>$100.00</td>
                    </tr>
                    <tr>
                      <th>Deposit Fee:</th>
                      <td>0.00% + $0.00 (min. $0.00 max. $0.00)</td>
                    </tr>
                    <tr>
                      <th>Debit Amount:</th>
                      <td>$100.00</td>
                    </tr>
                    <tr>
                      <th> Debit Amount:</th>
                      <td>100.00</td>
                    </tr>
                  </tbody></table>
                <br /><br />
                <form name="spend" method="post"><input type="hidden" name="form_id" defaultValue={16862961841002} /><input type="hidden" name="form_token" defaultValue="066e34da366d885f78e7b290c16190f8" />
                  <input type="hidden" name="a" defaultValue="deposit" />
                  <input type="hidden" name="action" defaultValue="confirm" />
                  <input type="hidden" name="type" defaultValue="process_1006" />
                  <input type="hidden" name="h_id" defaultValue={1} />
                  <input type="hidden" name="compound" defaultValue={0} />
                  <input type="hidden" name="amount" defaultValue={100.00} />
                  <table cellSpacing={0} cellPadding={2} border={0}>
                    <tbody><tr>
                        <td colSpan={2}><b>Required Information:</b></td>
                      </tr>
                      <tr>
                        <td>Transaction ID</td>
                        <td><input type="text" name="fields[1]" defaultValue className="inpts" /></td>
                      </tr>
                    </tbody></table>
                  <br /><input type="submit" defaultValue="Save" className="sbmt" /> &nbsp;
                  <input type="button" className="sbmt" defaultValue="Cancel" onclick="document.location='?a=deposit'" />
                </form>
              </div></div></div></div></div></div>
    {/* App footer start */}
    <div className="app-footer">© Deluxe Prostock</div>
    {/* App footer end */}
  </div>
  )
}

export default DepositPayment