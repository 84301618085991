import React, { useEffect, useRef, useState, useContext } from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter, useNavigate, Link, Route, Routes, Switch } from "react-router-dom";
import Nav from '../Components/Nav';
import Sidebar from '../Components/Sidebar';
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from 'sweetalert2'


function Profile() {
  const history = useNavigate();
  const [{ userdetails, loggedin, tradingpair, depositinfo }, dispatch] = useContext(GlobalContext);
  const [loading, setloading] = useState(false)

  const [password, setpassword] = useState("")
  const [password2, setpassword2] = useState("")
  const [fullname, setfullname] = useState("")
  const [address, setaddress] = useState("")
  const [phonenumber, setphonenumber] = useState("")
  const [dateOfBirth, setdateOfBirth] = useState("")
  const [country, setcountry] = useState("")


  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setloading(false)
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setloading(false)
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          setfullname(doc.data().fullname)
          setaddress(doc.data().Address)
          setphonenumber(doc.data().Phoneno)
          //  setdateOfBirth(doc.data().DateOfBirth)
          // Phoneno:phonenumber,
          // Address:address,
          // fullname:fullname,
          // DateOfBirth:dateOfBirth,
          setloading(false)
        } else {
          console.log("No such document!");
          setloading(false)
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };



  const updateUserProfile = async () => {

    let userid = f.auth().currentUser;
    let userids = userid.uid;
    var docRef = db.collection("users").doc(userids);
    console.log(phonenumber, address, fullname, dateOfBirth)
    console.log(fullname)
    const update = await docRef
      .update({
        Phoneno: phonenumber,
        fullname: fullname,
        address: address
      })
      .then(function () {
        console.log("Document successfully updated!");
        Swal.fire(
          'Update Complete!',
          'Your account has been updataed.',
          'success'
        )
      })
      .catch(function (error) {
        console.error("Error updating document: ", error);
      });
  };


  const changepassword = () => {

    if (password == "") {
      Swal.fire(
        'No Selected Passowrd?',
        'Please Select A Valid Password',
      )
      return

    }

    if (password != password2) {
      Swal.fire(
        'Password does not match?',
        'Password must match',
      )
      return
    }
    setloading(true)
    var user = f.auth().currentUser;
    user
      .updatePassword(password)
      .then(function () {
        setloading(false)
        Swal.fire({
          icon: 'success',
          title: 'password changed',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(function (error) {
        console.log(error)
        setloading(false)
        Swal.fire(
          'Something went wrong',
          `${error.message}`,
        )

      });
  };

  const save = () => {
    Swal.fire({
      icon: 'success',
      title: 'Account has been updated successfully',
      showConfirmButton: false,
      timer: 1500
    })
  }

  return (
    <div>
      <Helmet>

        <script src="https://deluxeprostock.com/dashboard/js/jquery.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/bootstrap.bundle.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/modernizr.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/moment.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/megamenu.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/megamenu/js/custom.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/slimscroll.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/slimscroll/custom-scrollbar.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/search-filter.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/search-filter/custom-search-filter.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/apex/apexcharts.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/salesGraph.js"></script>
        {/* <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/ordersGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/earningsGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/visitorsGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/customersGraph.js"></script>
    <script src="https://deluxeprostock.com/dashboard/vendor/apex/custom/home/sparkline.js"></script> */}
        <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.min.js"></script>
        <script src="https://deluxeprostock.com/dashboard/vendor/circliful/circliful.custom.js"></script>
        <script src="https://deluxeprostock.com/dashboard/js/main.js"></script>
      </Helmet>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      {/*--------------- www.hyipcustomize.com ---------*/}
      <meta name="designer" href="https://hyipcustomize.com/" />
      {/* Title */}
      <title>Techsgrowth | Dashboard</title>
      {/* *************
        ************ Common Css Files *************
    ************ */}
      {/* Bootstrap css */}
      <link rel="stylesheet" href="dashboard/css/bootstrap.min.css" />
      {/* Icomoon Font Icons css */}
      <link rel="stylesheet" href="dashboard/fonts/style.css" />
      {/* Main css */}
      <link rel="stylesheet" href="dashboard/css/main.css" />
      {/* *************
        ************ Vendor Css Files *************
    ************ */}
      {/* Mega Menu */}
      <link rel="stylesheet" href="dashboard/vendor/megamenu/css/megamenu.css" />
      {/* Search Filter JS */}
      <link rel="stylesheet" href="dashboard/vendor/search-filter/search-filter.css" />
      <link rel="stylesheet" href="dashboard/vendor/search-filter/custom-search-filter.css" />
      {/* Loading wrapper start */}
      <div id="loading-wrapper">
        <div className="spinner-border" />
        Loading...
      </div>
      {/* Loading wrapper end */}
      {/* Page wrapper start */}
      <div className="page-wrapper">
        {/* Sidebar wrapper start */}
        <Nav />
        {/* Sidebar wrapper end */}
        {/* *************
            ************ Main container start *************
        ************* */}
        <div className="main-container">
          {/* Page header starts */}
          <div className="page-header">
            {/* Row start */}
            <div className="row gutters">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-9">
                {/* Search container start */}
                <div className="search-container">
                  {/* Toggle sidebar start */}
                  <div className="toggle-sidebar" id="toggle-sidebar">
                    <i className="icon-menu" />
                  </div>
                  {/* Toggle sidebar end */}
                </div>
                {/* Search container end */}
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-3">
                {/* Header actions start */}
                <ul className="header-actions">
                  <li className="dropdown">
                    <a href="#" id="userSettings" className="user-settings" data-toggle="dropdown" aria-haspopup="true">
                      Hi {userdetails.fullname} &nbsp;
                      <span className="avatar">
                        <img src="https://img.icons8.com/?size=1x&id=kDoeg22e5jUY&format=png" alt="User Avatar" />
                        <span className="status busy" />
                      </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end md" aria-labelledby="userSettings">
                      <div className="header-profile-actions">
                        <a href="?a=edit_account"><i className="icon-user1" />Profile</a>
                        <a href="?a=security"><i className="icon-warning" />Security</a>
                        <a href="?a=logout"><i className="icon-log-out1" />Logout</a>
                      </div>
                    </div>
                  </li>
                </ul>
                {/* Header actions end */}
              </div>
            </div>
            {/* Row end */}
          </div>
          {/* Page header ends */}
          {/* Content wrapper scroll start */}
          <div className="content-wrapper-scroll">
            {/* Content wrapper start */}
            <div className="content-wrapper">
              {/* Row start */}
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  {/* Card start */}
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">Profile</div>
                    </div>
                    <div className="card-body">
                      <form action  name="editform"><input type="hidden" name="form_id" defaultValue={16862972223362} /><input type="hidden" name="form_token" defaultValue="aa685c5afe0196065a19951d1a9af334" />
                        <input type="hidden" name="a" defaultValue="edit_account" />
                        <input type="hidden" name="action" defaultValue="edit_account" />
                        <input type="hidden" name="say" defaultValue />
                        <div className="table-responsive">
                          <table className="table">
                            <tbody><tr>
                              <td>Account Name:</td>
                              <td>{userdetails.fullname}</td>
                            </tr><tr>
                                <td>Registration date:</td>
                                <td>Jun-7-2023 05:46:28 AM</td>
                              </tr><tr>
                                <td>Your Full Name:</td>
                                <td><input type="text" name="fullname" defaultValue={fullname} onChange={(e)=>{setfullname(e.target.value)}} className="form-control" />
                                </td></tr>
                          
                              <tr>
                                <td>Your Bitcoin Address:</td>
                                <td><input type="text" className="form-control" name="pay_account[1006][Address]" defaultValue="1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa" /></td>
                              </tr>
                              <tr>
                                <td>Your Tether USDT TRC20 Account ID:</td>
                                <td><input type="text" className="form-control" name="pay_account[1007][Account ID]" defaultValue="1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa" /></td>
                              </tr>
                              <tr>
                                <td>Your E-mail address:</td>
                                <td><input type="text"  defaultValue={userdetails.email} name="email" readOnly className="form-control" /></td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td><input type="button" onClick={updateUserProfile} defaultValue="Change Account data" className="btn btn-primary ml-auto" /></td>
                              </tr></tbody></table>
                        </div>
                      </form>



                      <form action  name="editform"><input type="hidden" name="form_id" defaultValue={16862972223362} /><input type="hidden" name="form_token" defaultValue="aa685c5afe0196065a19951d1a9af334" />
                        <input type="hidden" name="a" defaultValue="edit_account" />
                        <input type="hidden" name="action" defaultValue="edit_account" />
                        <input type="hidden" name="say" defaultValue />
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>New Password:</td>
                                <td><input type="password" name="password" onChange={(e)=>{setpassword(e.target.value)}} defaultValue className="form-control" /></td>
                              </tr><tr>
                                <td>Retype Password:</td>
                                <td><input type="password" name="password2"  onChange={(e)=>{setpassword2(e.target.value)}} defaultValue className="form-control" /></td>
                              </tr>
                              <tr>
                                <td>Your E-mail address:</td>
                                <td><input type="text"  defaultValue={userdetails.email} name="email" readOnly className="form-control" /></td>
                              </tr>
                              <tr>
                                <td>&nbsp;</td>
                                <td><input type="button"  onClick={changepassword} defaultValue="Change Account password" className="btn btn-primary ml-auto" /></td>
                              </tr></tbody></table>
                        </div>
                      </form>
                    </div></div></div>
              </div></div></div>
          {/* App footer start */}
          <div className="app-footer">© Techsgrowth</div>
          {/* App footer end */}
        </div>
        {/* Content wrapper scroll end */}
      </div>
      {/* *************
            ************ Main container end *************
        ************* */}
      {/* Page wrapper end */}
      {/* *************
        ************ Required JavaScript Files *************
    ************* */}
      {/* Required jQuery first, then Bootstrap Bundle JS */}
      {/* *************
        ************ Vendor Js Files *************
    ************* */}
      {/* Megamenu JS */}
      {/* Slimscroll JS */}
      {/* Search Filter JS */}
      {/* Apex Charts */}
      {/* Circleful Charts */}
      {/* Main Js Required */}
      {/*Start of Tawk.to Script*/}
      {/*End of Tawk.to Script*/}
    </div>
  )
}

export default Profile